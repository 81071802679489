'use client';
import { getClientScope } from '@effector/next';
import { attachReduxDevTools } from '@effector/redux-devtools-adapter';
import { attachLogger } from 'effector-logger';

type TReduxDevToolsAdapterProps = Readonly<{ children?: React.ReactNode }>;
export function ReduxDevToolsAdapter({ children }: TReduxDevToolsAdapterProps) {
    attachLogger({ name: 'modular' });

    const clientScope = getClientScope();
    if (clientScope) {
        /**
         * Notice, that we need to check for the client scope first
         *
         * It will be `null` at the server
         */
        attachReduxDevTools({
            scope: clientScope,
            name: 'modular-arena-client',
            trace: true,
        });
    }
    return <>{children}</>;
}
